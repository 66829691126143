import React from 'react'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import './App.css'
import AutoGrid from './Components/AutoGrid/AutoGrid'
import NavBar from './Components/NavBar/NavBar'
import RTL from './rtl';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import SignIn from './Components/Auth/SignIn'

class App extends React.Component {
  constructor() {
    super()

    this.toggleDark = this.toggleDark.bind(this)
    this.toggleDirection = this.toggleDirection.bind(this)
    this.toggleLanguage = this.toggleLanguage.bind(this)

    this.state = {
      theme: {
        palette: {
          type: 'light',
        },
        direction: 'ltr',
        overrides: {
          MuiTooltip : {
            tooltip: {
              color: "#fff",
              backgroundColor: "#3f51b5",
              fontSize: "12px"
            }
          }
        }
      },
      language: 'en'
    }
  }

  toggleDirection() {
    this.setState((prevState) => {
      let dir = "";
      if (prevState.theme.direction === "ltr") {
        dir = 'rtl'
      }
      else {
        dir = 'ltr'
      }

      window.document && window.document.getElementsByTagName('body')[0].setAttribute('dir', dir)

      const newTheme = { ...prevState.theme }
      newTheme.direction = dir
      return { theme: newTheme }
    })
  }

  toggleDark() {
    this.setState((prevState) => {
      let newPaletteType = "";
      if (prevState.theme.palette.type === "light") {
        newPaletteType = "dark"
        document.body.style = "background-color:black"
      }
      else {
        newPaletteType = "light"
        document.body.style = "background-color:white"
      }

      const newTheme = { ...prevState.theme }
      newTheme.palette.type = newPaletteType
      return { theme: newTheme }
    })
  }

  toggleLanguage() {
    this.setState((prevState) => {
      let lan = "";
      if (prevState.language === "en") {
        lan = 'te'
      }
      else {
        lan = 'en'
      }

      return { language: lan }
    })
  }

  render() {
    const muiTheme = createMuiTheme(this.state.theme);

    return (
      <BrowserRouter>
      <RTL>
        <MuiThemeProvider theme={muiTheme}>
          <NavBar
                theme={muiTheme}
                toggleDark={this.toggleDark}
                toggleDirection={this.toggleDirection}
                toggleLanguage={this.toggleLanguage}
                directionIsRTL={this.state.theme.direction === 'rtl'}
                language={this.state.language}
              />
        <Switch>
          <Route exact path='/' render={(props) => <AutoGrid {...props} language={this.state.language} />} />
          <Route path='/signin' render={(props) => <SignIn {...props} language={this.state.language} /> }></Route>
        </Switch>
        </MuiThemeProvider>
      </RTL>
      </BrowserRouter>
    )
  }
}

export default App;