import 'firebase/auth'

export const signIn = (credentials) => {
    return function (dispatch, getState, { getFirebase }) {
        const firebase = getFirebase();              
        firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(() => {
            dispatch({type:'LOGIN_SUCCESS'})        
        }).catch((err) => {
            dispatch({type:'LOGIN_ERROR', err})
        });
    }
}

export const signOut = () => {
    return function (dispatch, getState, {getFirebase}){
        const firebase = getFirebase();

        firebase.auth().signOut()
        .then(() => {
            dispatch({type:'LOGOUT_SUCCESS'})
        })
    }
}

export const signUp = (newUser) => {
    return function (dispatch, getState, { getFirebase }) {
        const firebase = getFirebase();
        firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password)
        .then(() => {
            dispatch({type:'SINGUP_SUCCESS'})
        })
        .catch((err) => {
            dispatch({type:'SINGUP_ERROR', err})
        });
    }
}