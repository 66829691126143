import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ComposerDialog from '../ComposerDialogs/ComposerDialog'
import Backdrop from '@material-ui/core/Backdrop';

import {firestoreConnect} from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  speedDialAction: {
    backgroundColor: '#3f51b5',
    color:'#fff',
    '&:hover': {
      backgroundColor: '#3f51b5',
    },       
  }
  
}));

function ComposeMenu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [openCompose, setOpenCompose] = React.useState(false);
  const [typeSelected, setTypeSelected] = React.useState({});

  const handleOpen = () => {
    setOpen(true);
    setBackdropOpen(true)
    props.toggleShow(false)
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(function(){ 
      setBackdropOpen(false)
      props.toggleShow(true) 
    }, 300);
    
  };


  function openDialog() {
    setOpenCompose(true);
  }
    
    function closeDialog(param) {    
      if(param === 'none'){
        setOpenCompose(false);
      }
    }

    function handleClick(type){
      setTypeSelected(type)
      handleClose()
      openDialog()
    }
    
  return (
    <div>
      <Backdrop open={backdropOpen} />
      <SpeedDial
        ariaLabel="Menu"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        open={open}
        onClick={open ? handleClose : handleOpen}
      >
        {props.types ? props.types.map(item => (
          <SpeedDialAction className={classes.speedDialAction}
            key={item.singular_name}
            icon={<i className='material-icons'> {item.icon}</i>}
            tooltipTitle={item.singular_name}
            tooltipOpen
            onClick={() => handleClick(item)}
          />
        )): null}

      </SpeedDial>
      
      <ComposerDialog 
        openDialog={openDialog} 
        closeDialog={closeDialog} 
        openCompose={openCompose} 
        language={props.language}
        directionIsRTL={props.directionIsRTL}
        type={typeSelected}
      />
 
      </div>
  )
}
const mapStateToProps = (state) => {
  return{
    profile: state.firebase.profile,
    types: state.firestore.ordered.types,
  }
}

export default compose(
    firestoreConnect((props) => [
      { collection: 'types',
        where: [
          ["lang", "==", props.language], 
          ["user_types", "array-contains", (props.userType ? props.userType: "user" )]
        ],
        orderBy: [
          ['sort_order']
        ],
      }
    ]),
    connect(mapStateToProps),
  )(ComposeMenu)