import Avatar from '@material-ui/core/Avatar';
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReplyIcon from '@material-ui/icons/Reply';
import ShareIcon from '@material-ui/icons/Share';
import clsx from 'clsx';
import React from 'react';
import LocalizedStrings from 'react-localization';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import 'moment/locale/te';

import { connect } from 'react-redux'


let strings = new LocalizedStrings({
  en:{
    "advisory" : "Advisory",
    "alert" : "Alert",
    "alarm" : "Alarm"
  },
  te: {
   "advisory" : "సలహా",
   "alert" : "జాగ్రత్త",
   "alarm" : "హెచ్చరిక"
  }
 });


const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: 300, 
    //width:330
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor:'rgba(41, 171, 226, 1)'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    verticalAlign:'middle',
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },
  box:{
    
  },
  cover: {
    width: 151,
  },
  img: {
    width:32,
    height:32
  },
  image: {
    width:100,
    height:100
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

const boxProps = {
  bgcolor: 'rgba(41, 171, 226, 1)',
  color:"white",
  m: 1,
  borderRadius: '8px 8px 0px 0px',
  style: { width: '10rem', height: '1.5rem' },
  fontSize: 12,
  align:'center',
  marginBottom: '-1px',
  marginLeft: '-0.5px'
};

function SimpleCard(props) {
  const classes = useStyles();
  
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  if(props.skeleton){
    return (
      <div>
        <Box {...boxProps} />
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Skeleton variant="circle" width={32} height={32} />
            }
            action={null}
            title={<Skeleton height={6} width="80%" />}
            subheader={<Skeleton height={6} width="40%" />}
          />
          <Skeleton variant="rect" className={classes.media} />
          <CardContent>
            <React.Fragment>
              <Skeleton height={6} />
              <Skeleton height={6} width="80%" />
            </React.Fragment>
          </CardContent>
          <CardActions >
            <Skeleton variant="rect" width={32} height={32} />
            <Skeleton variant="rect" width={32} height={32} />
            <Skeleton variant="rect" width={32} height={32} />
            <Skeleton variant="rect" width={32} height={32} />
            <Skeleton variant="rect" width={32} height={32} />
          </CardActions>
        </Card>
      </div>
    )
  }
  
  if(strings && props){
    strings.setLanguage(props.language)
    moment.locale(props.language)
  }

  const postType = (props.viewTypes) ? props.viewTypes.filter((item) => {
    return (item.type_id === props.post.category)
  }) : null

  return (
    <div>
    <Box {...boxProps} >
      <Typography variant="body1">
        {postType ? postType[0].singular_name : null}
      </Typography>
    </Box>

    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.post.authorName.substring(0,1)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={props.post.authorName}
        subheader={moment(props.post.createTimestamp.toDate()).calendar()}
      />
      {(props.post.imageUrl !== '') ? 
        <CardMedia
          className={classes.media}
          image={props.post.imageUrl}
          title={props.post.title}
        />
        : null }
      <CardContent>
      <Typography gutterBottom variant="subtitle2" >
      {props.post.title}
          </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.post.message}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="reply">
            <ReplyIcon />
          </IconButton>
          <IconButton aria-label="bookmark">
            <BookmarkIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <IconButton aria-label="flag">
            <i className="material-icons">flag</i>
          </IconButton>
        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Lorem epsum
          </Typography>
          <Typography paragraph>
          Lorem epsum
          </Typography>
          <Typography paragraph>
          Lorem epsum
          </Typography>
          <Typography>
          Lorem epsum
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state)
  return{
    viewTypes: state.firestore.ordered['viewTypes']
  }
}

export default connect(mapStateToProps)(SimpleCard)