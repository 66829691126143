const initialState = {
    authError:null,
    profile:null
}

const AuthReducer = (state = initialState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
        console.log("Login error")
        return {...state, authError: 'Login Failed'}

        case 'LOGIN_SUCCESS':
        console.log("Login success")
        return {...state, authError: ''}

        case 'LOGOUT_SUCCESS':
        console.log("Logout success")
        return {...state, logoutStatus: true}

        case 'SIGNUP_SUCCESS':
        console.log("Signup success")
        return {...state, authError: ''}
    
        case 'SIGNUP_ERROR':
        console.log("Signup failed")
        return {...state, authError: action.err.message}

        default:
        return state
    }
}

export default AuthReducer