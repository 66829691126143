import React, {useState} from 'react'
import { Chip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {firestoreConnect} from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'

import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
  en:{
 
  },
  te: {
 
  }
 });

function PostCategories(props) {
    if(strings && props){
        strings.setLanguage(props.language)
    }

    if(props.viewTypes != null){
    return  props.viewTypes.map((item) => {  
        return (
            <Grid item key={item.id}>
                <Chip 
                    key={item.id}
                    label={item.plural_name} 
                    color={!item.selected  ? `primary` : `default`}
                />
            </Grid>
        )
    }) 
    }
    else{
        return null
    }   
}

const mapStateToProps = (state) => {
    return{
      viewTypes: state.firestore.ordered['viewTypes']
    }
  }

export default compose(
        connect(mapStateToProps),
        firestoreConnect((props) => [
            {
                collection: 'types',
                where: [["lang", "==", props.language]],
                orderBy: [
                ['sort_order']
                ],
                storeAs: 'viewTypes'
            }
        ])
    )(PostCategories)







