const initialState = {
    subscribeError:null
}

const SubscriptionReducer = (state = initialState, action) => {
    switch(action.type){

        case 'SUBSCRIPTION_SUCCESS':
        console.log("Subscription success")
        return {...state, subscribeError: ''}

        case 'SUBSCRIPTION_ERROR':
        console.log("Subscription failed")
        return {...state, subscribeError: 'Subscription failed'}

        default:
        return state
    }
}

export default SubscriptionReducer