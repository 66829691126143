import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
      "subscribetext" : "Hi there! LOCL is still under development. Please drop in your email below and we'll send you an exclusive invite as soon as we are ready. Thank you",
      "signup" : "Create an account",
      "cancel" : "Cancel",
      "subscribe" : "Subscribe",
      "email" : "Email"
    },
    te: {
        "subscribetext" : "నమస్తే! LOCL ఇంకా నిర్మాణము లోనే ఉంది. మీ విలేఖ చిరునామా క్రింద పొందుపరచినట్లైనతే, వీలైనంత త్వరలో మీకు మా ప్రత్యేక నమోదు ఆహ్వానమును పంపుతాము. ధన్యవాదాలు",
        "signup" : "ఖాతా తెరవండి",
        "cancel" : "రద్దు చేయి",
        "subscribe" : "పొందుపరచు",
        "email" : "విలేఖ"
    }
  });

  
export default function SubscribeDialog(props) {

  if (strings && props) {
    strings.setLanguage(props.language)
  }

  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{strings.signup}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           {strings.subscribetext}
          </DialogContentText>
          
          <TextField
            required
            autoFocus
            margin="dense"
            id="subscriptionEmail"
            label={strings.email}
            type="email"
            fullWidth
            onChange={(e) => {props.handleChange(e)}}
          />
          
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleSubscribeDialog} variant="outlined" >
            {strings.cancel}
          </Button>
          <Button onClick={props.handleSubscription} variant="contained" color="primary">
            {strings.subscribe}
          </Button>
        </DialogActions>
      </Dialog>
  );
}
