import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux'
import { signIn } from '../../store/Actions/AuthActions'
import { saveSubscription } from '../../store/Actions/SubscriptionActions'
import {Redirect} from 'react-router-dom'
import LocalizedStrings from 'react-localization';
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import heroImage from '../../Assets/happypeople.jpg'; 
import SubscribeDialog from '../Subscribe/SubscribeDialog'

let strings = new LocalizedStrings({
    en: {
      "signin": "Sign In",
      "email" : "Account ID",
      "password" : "Password",
      "submit" : "Enter",
      "login_failed" : "Login attempt failed",
      "tagline" : "This is your friendly, neighbourhood social network for sharing, caring, games, hobbies, exchanges and more",
      "knowmore" : "Know more",
      "meaning" : "/ˈləʊk(ə)l/",
      "or" : "or",
      "signup" : "Create an account",
      "subscribersaved" : "Subscription email saved successfully",
      "subscriberfailed" : "Sorry, the transaction has failed"
    },
    te: {
        "signin": "ప్రవేశము",
        "email" : "ఖాతా గుర్తింపు",
        "password" : "గుప్తపది",
        "submit" : "అనుమతించు",
        "login_failed" : "ప్రవేశ ప్రయత్నము విఫలమైనది",
        "tagline" : "ఇది మీ ఇరుగు పొరుగు మిత్రుల సమూహం.. ఆటలకీ, మాటలకీ, ఇష్టాలకీ, ఇచ్చిపుచ్చుకోవడాలకీ, ఇంకా చాలా",
        "knowmore" : "మరింత సమాచారం",
        "meaning" : "లోకల్",
        "or" : "లేక",
        "signup" : "ఖాతా తెరవండి",
        "subscribersaved" : "మీ విలేఖ చిరునామా విజయవంతంగా పొందుపరచబడినది",
        "subscriberfailed" : "క్షమించండి, ఈ ప్రయత్నం విఫలమైనది"
    }
  });

const classes = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'white',
        backgroundColor: 'black'
    },
    progress: {
        margin: theme.spacing(2),
    }
}));

class SignIn extends React.Component{
    constructor(){
        super()
        this.state = 
            {
                email: '', 
                password: '',
                isSubscribeDialogOpen: false,
                subscriptionEmail: '',
                isSuccessSnackBarOpen: false,
                isErrorSnackBarOpen: false,
                subscribeError: null
            }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubscribeDialog = this.handleSubscribeDialog.bind(this)
        this.handleSubscription = this.handleSubscription.bind(this)
    }
    
    handleSubmit(e){
         e.preventDefault()
         this.props.signIn(this.state)
    }

    handleChange(e){
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleSubscribeDialog(){
        this.setState((prevState) => {
            return {isSubscribeDialogOpen : !(prevState.isSubscribeDialogOpen)}
        })
    }

    handleSubscription(){
        this.props.saveSubscription(this.state)
        this.handleSubscribeDialog()
    }

    render(){
        const {email, authError} = this.props

        if(email != null){
            return (
              <Redirect to='/' />
            )
        }
        
        if (strings && this.props) {
            strings.setLanguage(this.props.language)
        }
        
        return (           
            <Grid 
                container 
                direction="row"
                alignItems="flex-start"
                className={classes.root} 
            >
                <Grid item align="center" > 
                <CardMedia
                    component="img"
                    alt="Happy people"
                    image={heroImage}
                    title="Happy people"
                    style={{paddingTop:'63px'}}
                />
                <Typography variant="body2" component="p">
                    &nbsp;&nbsp;&nbsp;&nbsp;Picture courtesy: <a href="https://www.flickr.com/photos/98769412@N06/">ScoopPORTFOLIO</a>
                </Typography>
                </Grid>
            <Grid item style={{alignItems:'stretch',flex:1}} >
                <Paper square className={classes.root} style={{boxShadow:'none', height:'100%'}}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    color="inherit"
                    style={{paddingTop:'100px',paddingBottom:'100px'}}
                >
                    <Grid 
                        item 
                        color="inherit"
                    >
                    <Typography variant="h6" noWrap>
                        {strings.signin}
                    </Typography>
                    </Grid>

                    <form className={classes.container} autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid 
                        item 
                        color="inherit"
                    >
                        <TextField
                        required
                        autoFocus
                        id="email"
                        label={strings.email}
                        className={classes.textField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        margin="normal"
                        onChange={this.handleChange}
                    />
                    </Grid>
                    <Grid item color="inherit">
                        <TextField
                            required
                            id="password"
                            label={strings.password}
                            className={classes.textField}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item color="inherit" align="center" style={{paddingTop:'40px'}}> 
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            margin="normal"
                            className={classes.button}
                        >
                            {strings.submit}
                        </Button>
                    </Grid>
                </form>
                <Grid item color="inherit" align="center" style={{padding:5}}>
                    {strings.or}
                </Grid>
                <Grid item color="inherit" align="center" >
                <Button 
                            variant="outlined" 
                            margin="normal"
                            className={classes.button}
                            onClick={this.handleSubscribeDialog}
                        >
                            {strings.signup}
                        </Button>
                        
                        {(this.props.subscribeError !== '') ?
                        <SubscribeDialog 
                            language={this.props.language} 
                            handleSubscribeDialog={this.handleSubscribeDialog} 
                            handleSubscription={this.handleSubscription} 
                            handleChange={this.handleChange}
                            open={this.state.isSubscribeDialogOpen}
                        />
                        : null }
                </Grid>
                </Grid>
                <Grid item color="inherit" >
                    { authError ? <Typography>{strings.login_failed} </Typography> : null    } 
                </Grid>
                <Grid item color="inherit" >
                <Paper className={classes.card} style={{boxShadow:'none'}} >
                    <CardContent>
                        <Typography variant="h5" component="h2">
                        LOCL
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {strings.meaning}
                        </Typography>
                        <br/>
                        <Typography variant="body2" component="p">
                        {strings.tagline}
                        </Typography>
                    </CardContent>
                    <CardActions >
                        <Button 
                            size="small"
                            variant="outlined" 
                            margin="normal"
                            className={classes.button}
                        >
                            {strings.knowmore}
                        </Button>
                    </CardActions>
                </Paper>
                </Grid>
                </Paper>
                </Grid>
                
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return{
      authError: state.auth.authError,
      email:state.firebase.auth.email,
      subscribeError: state.subscribe.subscribeError
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      signIn: (creds) => dispatch(signIn(creds)),
      saveSubscription: (email) => dispatch(saveSubscription(email))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SignIn)