import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import logo from '../../Assets/logo-dark.svg';
import { signOut } from '../../store/Actions/AuthActions';
import PostCategories from '../CategoryTypePane/CategoryTypePane';
import ComposerDialog from '../ComposerDialogs/ComposerDialog';
import LocationSelector from '../LocationSelector/LocationSelector'

let strings = new LocalizedStrings({
  en: {
    "logotext": "LOCL.in"
  },
  te: {
    "logotext": "LOCL.in"

  }
});

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    paddingLeft: 5,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  categories: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'scroll',
      paddingLeft: 3
    },

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 30,
    }
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  
}));


function NavBar(props) {
  const {email} = props

  const [openCompose, setOpenCompose] = React.useState(false);
  const [level,setLevel] = React.useState('');


function openDialog() {
  setLevel('')
  setOpenCompose(true);
}

function closeDialog(param) {
  setLevel(param)

  if(param === 'none'){
    setOpenCompose(false);
  }

}

  const classes = useStyles();

  if (strings && props) {
    strings.setLanguage(props.language)
  }

  document.title = strings.logotext

  const flipImageHorizontally = props.directionIsRTL ? { transform: 'scaleX(-1)' } : null;

  return (
    <div className={classes.grow}>
      <AppBar color="primary" >
        <Toolbar>
            <img src={logo} alt="locl" height="32" width="32" />
            <Typography className={classes.title} variant="h5" style={{ color: '#fff' }} noWrap>
              {strings.logotext}
            </Typography>
            
            {(email != null) ? 
              <LocationSelector/>
            : null }

          <div className={classes.grow} />
          <div >
            <IconButton aria-label="Toggle light/dark " onClick={props.toggleDark} color="inherit" >
              <i className="material-icons">brightness_4</i>
            </IconButton>

            <IconButton aria-label="Toggle direction" color="inherit" onClick={props.toggleDirection} >
              <i className="material-icons" style={flipImageHorizontally}> format_textdirection_r_to_l</i>
            </IconButton>

            <IconButton aria-label="Change language" color="inherit" onClick={props.toggleLanguage} >
              <i className="material-icons">translate</i>
            </IconButton>

            {(email != null) ?
              <IconButton aria-label="Sign out" color="inherit" onClick={props.signOut} >
                <i className="material-icons" style={flipImageHorizontally}> exit_to_app</i>
              </IconButton>
              : null}

          </div>
        </Toolbar>

        {(email != null) ?
        <Paper className={classes.categories} style={{ paddingTop: '15px', height: "40px", borderRadius: 0 }} color="default">
          <Grid container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
            style={{ flexWrap: 'nowrap', height: '40px', }}
          >
            <PostCategories language={props.language} />
          </Grid>
        </Paper>
        : null}
      </AppBar>

      <ComposerDialog 
        openDialog={openDialog} 
        closeDialog={closeDialog} 
        level={level} 
        openCompose={openCompose} 
        language={props.language}
        directionIsRTL={props.directionIsRTL}
      />
 

    </div> 

  );
}

const mapStateToProps = (state) => {
  return{
    email:state.firebase.auth.email
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)