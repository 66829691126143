
import firebase from 'firebase/app'
import 'firebase/firestore'


export const createPost  = (post) => {
    return (dispatch, getState, {getFirebase, getFirestore }) => {

        const firestore = firebase.firestore()
        const profile = getState().firebase.profile
        const uid = getState().firebase.auth.uid

        firestore.collection('Posts').add({
            ...post, 
            authorName:profile.firstName + " " + profile.lastName,
            authorId: uid,
            createTimestamp: new Date()
        }).then(() => {
            dispatch({type : "CREATE_POST"}, post);
        }).catch((err) => {
            dispatch({type : "CREATE_POST_ERROR", err});
        })
    }
}
