import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SimpleCard from '../SimpleCard/SimpleCard';
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import {firestoreConnect} from 'react-redux-firebase'
import { compose } from 'redux'
import ComposeMenu from '../ComposeMenu/ComposeMenu'

class AutoGrid extends React.Component {
  constructor(props){
    super(props)
    this.state = {show : true, innerHeight:window.innerHeight}

    this.toggleShow = this.toggleShow.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }  

  updateDimensions() {
    this.setState({innerHeight: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleShow(bool){
    this.setState({show : bool})
  }

  render() {
    if(this.props.isLoaded && this.props.isEmpty){
      return (
        <Redirect to='/signin' />
      )
    }

  const classes = makeStyles((theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingTop: "100px"
      },
      paper: {
        padding: theme.spacing(20),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    }),
  );

  let cards = (this.props.posts == null) 
    ? Array.from(Array(10), (_,x) => 
        <Grid item color="inherit" key={x}>
          <SimpleCard skeleton="true"  />
        </Grid>
      )
    : this.props.posts.map(post => {  
        return (
            <Grid item color="inherit" key={post.id}>
              <SimpleCard language={this.props.language} post={post}  />
            </Grid>
          )
        }) 
            
  return (
    <div style={{overflow:'scroll', height:this.state.innerHeight}}>
    <Box className={classes.root} >

      <Container 
        maxWidth="xl" 
        color="inherit" 
        style={{paddingTop: "130px"}} >
        
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          color="inherit"
        >
        {this.state.show ? cards : null}
        </Grid>
      </Container>
     

    </Box>
    { (this.props.isLoaded && !this.props.isEmpty) ?
      <ComposeMenu
        language={this.props.language}
        directionIsRTL={this.props.directionIsRTL}
        userType={this.props.profile.userType}
        toggleShow={this.toggleShow}
      />
      : null}
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    isLoaded: state.firebase.auth.isLoaded,
    isEmpty: state.firebase.auth.isEmpty,
    email:state.firebase.auth.email,
    posts: state.firestore.ordered.Posts,
    profile: state.firebase.profile 
  }
}

export default compose(
  connect(mapStateToProps),
    firestoreConnect((props) => [
      { collection: 'Posts',
        orderBy: [
          ["createTimestamp", "desc"]
        ]
      },
    ])
  )(AutoGrid)