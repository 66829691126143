import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseButtonIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListSubheader from '@material-ui/core/ListSubheader';
import ComposeForm from './ComposeForm'
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en: {
    "compose" : "Compose",
  },
  te: {
    "compose" : "కూర్చు",
  }
});


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ComposerDialog(props){
  const classes = useStyles();
  const flipImageHorizontally = props.directionIsRTL ? { transform: 'scaleX(-1)' } : null;

  if (strings && props) {
    strings.setLanguage(props.language)
  }
  
  return (
    <Dialog fullScreen open={props.openCompose} onClose={props.closeDialog} TransitionComponent={Transition}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={() => props.closeDialog('none')} aria-label="close">
          <ArrowBackIcon style={flipImageHorizontally} />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {strings.compose}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={() => props.closeDialog('none')} aria-label="close">
          <CloseButtonIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  
      <List>
        <ListSubheader component="div" >{props.type ? props.type.singular_name: null}</ListSubheader>
        <Divider />
      </List>
    
      <ComposeForm 
        language={props.language} 
        level={props.level}
        closeDialog={() => props.closeDialog('none')}
        type={props.type}
      />
    </Dialog>
  )
}

export default ComposerDialog
