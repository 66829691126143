  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/auth'
  import 'firebase/storage'

  var firebaseConfig = {
    apiKey: "AIzaSyCWewANhPhxJocirYSuqSTnOsmvYA8opPA",
    authDomain: "loclindia.firebaseapp.com",
    databaseURL: "https://loclindia.firebaseio.com",
    projectId: "loclindia",
    storageBucket: "loclindia.appspot.com",
    messagingSenderId: "551523587380",
    appId: "1:551523587380:web:ba78ab3d7da635ca148161"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({timestampsInSnapshots: true})

  const storage = firebase.storage();

  export {
    storage, firebase as default
  }