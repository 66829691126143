import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import RootReducer from './store/Reducers/RootReducer'
import thunk from 'redux-thunk'
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {getFirestore, createFirestoreInstance } from 'redux-firestore'
import { applyMiddleware, createStore } from 'redux';
import firebase from 'firebase/app'
import  'firebase/database'
import 'firebase/firestore'
import {getFirebase} from 'react-redux-firebase'

const rrfConfig = { 
  useFirestoreForProfile:true, 
  userProfile: 'users', 
  autoPopulateProfile:true,
  updateProfileOnLogin:true
}

const store = createStore(RootReducer,
    applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore}))
); 

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
  enableLogging: true
 }

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>
  ,document.getElementById('root'));

serviceWorker.unregister();


