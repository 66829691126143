import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LocalizedStrings from 'react-localization';
import TextField from '@material-ui/core/TextField';
import AddAPhotoButtonIcon from '@material-ui/icons/AddAPhoto';
import { connect } from 'react-redux'
import { createPost } from '../../store/Actions/PostActions'
import {storage} from '../../config/fbConfig'


let strings = new LocalizedStrings({
    en: {
      "picture" : "Picture",
      "title" : "Title",
      "message" : "Message",
      "post" : "Post"
    },
    te:{
      "picture" : "చిత్రము",
      "title" : "శీర్షిక",
      "message" : "సందేశము",
      "post" : "ప్రచురించు"
    }
})

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 500,
  },
  button1: {
    margin: theme.spacing(1),
    color:'grey'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

function ComposeForm(props) {
  const classes = useStyles();
  
  const [title, setTitle] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [image, setImage] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')
  const [progress, setProgress] = React.useState('')
  
  if(strings && props){
    strings.setLanguage(props.language)
  }

  function handleFileChange(e) {
    if (e.target.files[0]) {
      const image = e.target.files[0];

      const metadata = {
        contentType: 'image/jpeg'
      };

      let uploadTask = null;
      let storageRef = null;

      try{
        storageRef = storage.ref('images/'+image.name)
      }
      catch(err){
        console.log(err)
      }

      try{
        uploadTask = storageRef.put(image, metadata);
      }
      catch(err){
        console.log(err)
      }

      uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log('Upload is ' + progress + '% done');
        setProgress(progress);
      }, 
      (error) => {
        console.log(error)
      }, 
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL);
          setImageUrl(downloadURL);
        });
    });

    }
  }

  function handleClick(e){
      props.createPost({
          category:props.type.type_id, 
          title:title, 
          message:message, 
          imageUrl:imageUrl
        })
      props.closeDialog()
  }

  return (
    <Container maxWidth="xs" color="inherit" >
    <Card className={classes.card}>
        <CardMedia
                className={classes.media}
                image={imageUrl || 'https://place-hold.it/320x180/666'}
                title={strings.picture}
        />
        <CardActions disableSpacing style={{margin:'none', padding:0}}>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: 'none' }}
            id="imageUpload"
            type="file"
            onChange={handleFileChange}
          />

          {/* TODO: The grey color should come automatically from the theme */}
          <label htmlFor="imageUpload">
            <AddAPhotoButtonIcon className={classes.button1} /> 
          </label> 
         {/*  <progress value={progress} max="100"/> */}
        </CardActions>

        <CardContent style={{margin:'none', paddingTop:0}}>
          <TextField
            autoFocus
            id="title"
            label={strings.title}
            fullWidth
            className={classes.textField}
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            />
          <TextField
            id="message"
            label={strings.message}
            multiline
            fullWidth
            rows="3"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
        />
        </CardContent>
      <CardActions>
      <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            margin="normal"
            fullWidth
            onClick={handleClick}
            className={classes.button}
        >
            {strings.post}
        </Button>
      </CardActions>
    </Card>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return{
    postError: state.post.postError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPost: (params) => dispatch(createPost(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComposeForm)