
const initialState = {
    postError: null,
    posts: []
}

const PostReducer = (state = initialState, action) => {
    switch(action.type){
        case 'CREATE_POST':
            console.log("Create post successful ")
            return {...state, postError:''}

        case 'CREATE_POST_ERROR':
            console.log("Create post failed")
            return {...state, postError: 'Posting Failed'}

        default:
           return state  
    }
}

export default PostReducer