
import AuthReducer from './AuthReducer'
import PostReducer from './PostReducer'
import SubscriptionReducer from './SubscriptionReducer'
import {combineReducers} from 'redux'
import {firebaseReducer} from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'

const RootReducer = combineReducers({
     auth: AuthReducer, 
     post: PostReducer,
     firebase:firebaseReducer,
     subscribe:SubscriptionReducer,
     firestore: firestoreReducer
})

export default RootReducer